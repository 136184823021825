.osano-cm-widget {
  display: none;
}

#root {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #000000;
  width: 100%;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
}

.check-mail .completed-img:before {
  top: 0;
  left: 0;
  right: auto;
}

.completed-img:before {
  content: '';
  position: absolute;
  width: 87px;
  height: 87px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  top: -7px;
  right: 15px;
  z-index: -1;
}

#minefield-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(154.49deg, rgba(121, 117, 131, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), linear-gradient(180deg, rgba(35, 37, 38, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
}

#minefield-background>#screen {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(../public/images/game-bg--310w-5blur-50pct.png);
  background-position: center 65%;
  background-repeat: no-repeat;
}